import React from "react";
import NavbarArtikel from "../components/NavbarArtikel";
import ArticlePage from "./Articles";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avt from "../assets/images/avatar/avatarsupport.png";

// import Igg from "../components/Instagrams";
const ArticlesWrap = () => {
  return (
    <>
      <NavbarArtikel />
      <ArticlePage />
      <FloatingWhatsApp
        phoneNumber="+628170138883"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={avt}
      />
    </>
  );
};

export default ArticlesWrap;
