import { Link } from "react-router-dom";
// import artikelNofound from "../assets/images/artikel/articlenotfound.png";
import drt from "../assets/drtonyprofile.png";
import Moment from "moment";

const Card = ({ post }) => {
  return (
    <>
      <div
        key={post.id}
        className="card dark:bg-gray-50 p-1 w-full shadow-lg rounded-lg transition duration-300 ease-in-out hover:shadow-2xl"
      >
        <Link to={`/posts/${post.id}`} className="space-y-5">
          <div class="dark:bg-gray-800 dark:text-gray-100">
            <div class="container max-w-4xl px-10 py-6 mx-auto rounded-lg shadow-sm dark:bg-gray-900">
              {/* <img
            src={post.featured_src ? post.featured_src : artikelNofound}
            alt={post.title.rendered}
            className="w-full h-48 object-contain"
          /> */}

              <div class="flex items-center justify-between">
                <span class="text-sm dark:text-gray-400">
                  {Moment(post.date).fromNow()}
                </span>
              </div>
              <div class="mt-3 ">
                <div class="text-2xl font-bold hover:underline">
                  {post.title.rendered}
                </div>
                <p
                  class="mt-2 line-clamp-4"
                  dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                ></p>
              </div>
              <div class="flex items-center justify-between mt-4 hover:underline">
                Read more
                <div>
                  <div class="flex items-center">
                    <img
                      src={drt}
                      alt="avatar"
                      class="object-cover w-10 h-10 mx-4 rounded-full dark:bg-gray-500"
                    />
                    <span class="hover:underline dark:text-gray-400">
                      dr.Tony Sukentro Sp.B
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {/* <div
        key={post.id}
        className="card bg-white p-3 w-full shadow-lg rounded-lg transition duration-300 ease-in-out hover:shadow-2xl"
      >
        <Link to={`/posts/${post.id}`} className="space-y-5">
          <img
            src={post.featured_src ? post.featured_src : artikelNofound}
            alt={post.title.rendered}
            className="w-full h-64 object-cover"
          />
          <h2 className="text-lg font-bold line-clamp-1">
            {post.title.rendered}
          </h2>
          <p
            className="line-clamp-3"
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          ></p>
        </Link>
      </div> */}
    </>
  );
};

export default Card;
