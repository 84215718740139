import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import thedrs from "../assets/doctors.png";
// import ShowMoreText from "react-show-more-text";
import { Link as LinkRouter } from "react-router-dom";

const Home = ({ resultRef }) => {
  // const [name, setName] = useState("");

  const handleClick = () => {
    resultRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  // const executeOnClick = (isExpanded) => {
  //   console.log(isExpanded);
  // };

  return (
    <div name="home" className="w-full h-screen bg-[#BBD6B8] background ">
      {/* Container */}

      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <figcaption class="mt-32 text-xl text-left text-gray-700 sm:text-4xl font-semibold pt-2">
          <div className="flex -ml-5">
            {" "}
            <img
              className=" h-[130px]  rounded-lg mt-[-30px] md:h-[200px]"
              src={thedrs}
              alt=""
            ></img>
          </div>
          Welcome to bedahumum.com
        </figcaption>
        <p className="text-gray-700 text-sm py-4 max-w-[800px] font-semibold text-justify sm:text-justify sm:text-base">
          Sepertinya era digitalisasi dunia kesehatan tidak dapat dielakan lagi.
          Kebutuhan masyarakat di Indonesia akan layanan kesehatan yang
          transparan dan mudah di jangkau menjadi harapan bagi mereka, terutama
          mereka yang tinggal di tempat2 terpencil dimana layanan kesehatan amat
          sulit dijangkau. Melihat kebutuhan ini maka Dr Tony Sukentro SpB
          merasa terpanggil untuk memberikan kemampuannya bukan hanya sebagai
          dokter tapi juga sebagai digital health provider untuk menjawab
          tantangan ini. Sebagai salah satu pengurus di...
          <LinkRouter to="/intro" className="text-green-700">
            Lanjut Membaca
          </LinkRouter>
        </p>

        <div>
          <button
            onClick={handleClick}
            className="text-gray-600 bg-[#94AF9F] group border-2 px-6 py-3 my-2 flex items-center
           hover:bg-[#DBE4C6] hover:border-[#DBE4C6] hover:text-gray-900"
          >
            View Surgeon Speciality
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 " />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
