import stwc from '../assets/projects/stwclogo.png'
import harapanbunda from '../assets/projects/harapanbundalogo.png'
// import dharma from '../assets/projects/dharmanugrahalogo.png'
import emc from '../assets/projects/emclogo.png'


export const data=[
    // {
    //     id:1,
    //     name:"Dharma Nugraha Hospital, Jakarta, Indonesia",
    //     years:"2007 – 2017",
    //     image:dharma,
    //     github:"https://dharmanugraha.co.id/",
    //     live:"",
    // },
    {
        id:1,
        name:"EMC Hospital, Jakarta, Indonesia",
        years:"2010 – present ",
        image:emc,
        github:"https://www.emc.id/id/",
        live:"",
    },
    {
        id:2,
        name:"Harapan Bunda Hospital, Jakarta, Indonesia",
        years:"2007 – present",
        image:harapanbunda,
        github:"https://sentramedikahospitals.com/hospital/harapan-bunda",
        live:"",
    },
    {
        id:3,
        name:"ST Wasir Center Clinic, Jakarta, Indonesia",
        years:"2021 – present",
        image:stwc,
        github:"https://www.stwasir.com/",
        live:"",
    },


]