import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaTiktok,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

import Logo from "../assets/logo.png";
import { Link } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#BBD6B8] text-gray-700">
      <div>
        <a href="/">
          <img src={Logo} alt="Logo Image1" style={{ width: "250px" }} />
        </a>
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="sponsor" smooth={true} duration={500} offset={-50}>
            Sponsor
          </Link>
        </li>
        <li>
          <Link to="Instagrams" smooth={true} duration={500}>
            Instagrams
          </Link>
        </li>
        <li>
          <Link to="profile" smooth={true} duration={500}>
            Profile
          </Link>
        </li>
        <li>
          <Link to="speciality" smooth={true} duration={500} offset={-100}>
            Speciality
          </Link>
        </li>
        <li>
          <Link to="work" smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li>
          <Link to="trainings" smooth={true} duration={500} offset={-100}>
            Trainings
          </Link>
        </li>

        <li>
          <Link to="gallery" smooth={true} duration={500} offset={-100}>
            Gallery
          </Link>
        </li>
        <li>
          <LinkRouter to="/article">Article</LinkRouter>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#ffffff] flex flex-col justify-center items-center"
        }
      >
        <li className="py-3 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="sponsor"
            smooth={true}
            duration={500}
            offset={-50}
          >
            Sponsor
          </Link>
        </li>

        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="Instagrams"
            smooth={true}
            duration={500}
            offset={-20}
          >
            Instagrams
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="profile"
            smooth={true}
            duration={500}
            offset={-50}
          >
            Profile
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="speciality"
            smooth={true}
            duration={500}
            offset={-60}
          >
            Speciality
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="work"
            smooth={true}
            duration={500}
            offset={-80}
          >
            Work
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="trainings"
            smooth={true}
            duration={500}
            offset={-80}
          >
            Trainings
          </Link>
        </li>

        <li className="py-3 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="gallery"
            smooth={true}
            duration={500}
            offset={-80}
          >
            Gallery
          </Link>
        </li>
        <li className="py-3 text-4xl">
          {" "}
          {/* <Link
            onClick={handleClick}
            to="article"
            smooth={true}
            duration={500}
            offset={-80}
          >
            Article
          </Link> */}
          <li>
            <LinkRouter to="/article" onClick={handleClick}>
              Article
            </LinkRouter>
          </li>
        </li>
      </ul>

      {/* Social icons */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-red-600">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.youtube.com/tonysurgeon27"
              target="_blank"
              rel="noreferrer"
            >
              Youtube <FaYoutube size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#405DE6]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.instagram.com/tonybedah27"
              target="_blank"
              rel="noreferrer"
            >
              Instagram <FaInstagram size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#000000]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.tiktok.com/@tonysurgeon27"
              target="_blank"
              rel="noreferrer"
            >
              Tiktok <FaTiktok size={30} />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto: tonybedah27@yahoo.com"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
