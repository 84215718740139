import React, { useState } from "react";
import { serti } from "../data/gallery.js";
import { GrClose } from "react-icons/gr";
import "../components/serti.css";

const Trainings = () => {
  const [model, setModel] = useState(false);
  const [tempimgScr, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <div name="trainings" className="w-full bg-[#BBD6B8] text-gray-700">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 text-gray-700 border-green-600 ">
            Training Certificate
          </p>
        </div>

        <br />
        <div className="md:columns-4 sm:columns-2  bg-[#BBD6B8] text-gray-700 ">
          {serti.map((item, index) => {
            return (
              <div
                className=" shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                key={index}
                onClick={() => getImg(item.imgSrc)}
              >
                <img
                  className="rounded-xl"
                  src={item.imgSrc}
                  alt="sertidokton"
                />
              </div>
            );
          })}

          <div className={model ? "model open" : "model"}>
            <img src={tempimgScr} alt="popimg" />
            <GrClose onClick={() => setModel(false)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trainings;
