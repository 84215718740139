import React from "react";
import Sponsors from "./components/Sponsors";
import Home from "./components/Home";
// import Navbar from "./components/Navbar";
import Igs from "./components/Instagrams";
import Trainings from "./components/Trainings";
import Work from "./components/Work";
import Profile from "./components/Profile";
import Gallery from "./components/Gallery";
import Speciality from "./components/Speciality";

//import { useRef } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avt from "./assets/images/avatar/avatarsupport.png";

const Bedahumum = ({ resultRef }) => {
  //const resultRef = useRef(null);

  return (
    <>
      {/* <Navbar /> */}
      <Home resultRef={resultRef} />
      <Sponsors />
      <Igs ref={resultRef} />
      <Profile ref={resultRef} />
      <Speciality ref={resultRef} />
      <Work />
      <Trainings />
      <Gallery />

      <FloatingWhatsApp
        phoneNumber="+628170138883"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={avt}
      />
    </>
  );
};

export default Bedahumum;
