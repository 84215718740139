import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avt from "../assets/images/avatar/avatarsupport.png";
import NavbarArtikel from "../components/NavbarArtikel";
import { Link as LinkRouter } from "react-router-dom";

const Intro = () => {
  const isSingle = false;

  return (
    <>
      <NavbarArtikel isSingle={isSingle} />

      <section className="bg-[#BBD6B8] p-10 min-h-screen">
        <div className="sm:text-center pb-8 pl-4 "></div>
        <div className="max-w-5xl mx-auto text-justify">
          <p className="text-gray-700 text-sm py-4 max-w-[800px] font-semibold text-justify sm:text-justify sm:text-base">
            Sepertinya era digitalisasi dunia kesehatan tidak dapat dielakan
            lagi. Kebutuhan masyarakat di Indonesia akan layanan kesehatan yang
            transparan dan mudah di jangkau menjadi harapan bagi mereka,
            terutama mereka yang tinggal di tempat-tempat terpencil dimana
            layanan kesehatan amat sulit dijangkau.
            <p className="pt-3">
              Melihat kebutuhan ini maka Dr Tony Sukentro SpB merasa terpanggil
              untuk memberikan kemampuannya bukan hanya sebagai dokter tapi juga
              sebagai digital health provider untuk menjawab tantangan ini.
            </p>
            <p className="pt-3">
              Sebagai salah satu pengurus di{" "}
              <a
                href="https://awmiofficial.com/"
                target="_blank"
                rel="noreferrer"
                className="italic text-cyan-800"
              >
                Asosiasi Wisata Medis Indonesia ( AWMI){" "}
              </a>{" "}
              Dr Tony sangat sadar pentingnya akses layanan digital yang mudah,
              murah dan akurat Sehingga lahirlah{" "}
              <LinkRouter to="/" className="text-green-600">
                <span className="italic">www.bedahumum.com</span>
              </LinkRouter>{" "}
              suatu portal kesehatan Gratis yang dapat diakses dimana saja dan
              kapan saja, dengan sistem webbased maka pengguna tidak usah
              repot-repot mendownload aplikasi dan menunggu oleh dijawab oleh
              dokter, khususnya dalam keadaan darurat maka kecepatan jawaban
              dari dokter akan sangat diharapkan pengguna.
            </p>
            <p className="pt-3">
              Saat ini layanan
              <LinkRouter to="/" className="text-green-600">
                <span className="italic">www.bedahumum.com</span>
              </LinkRouter>{" "}
              ini masih terbatas pada bidang bedah saja, karena sesuai keahlian
              Dr Tony Sukentro SpB dkk sebagai ahli bedah.{" "}
            </p>
            <p className="pt-3">
              Dr Tony tentu tidak sendiri dalam menjalankan misinya, beliau
              dibantu dokter bedah tulang ahli trauma dan spine dari Jepang Dr
              Herman Gofara SpOT Spine dan Juga Dokter Bedah Jantung Vaskular Dr
              Marolop Pardede, Sp. BTKV, Subsp . VE (K), MH sehingga cakupan
              layanan bidang bedah ini semakin luas mulai dari konsultasi
              penyakit, kecelakaan, kelainan bawaan dll.
            </p>
            <p className="pt-3 font-bold">
              "Kedepannya kami juga akan bekerja sama dengan bidang lain seperti
              Jantung, saraf, anak, kebidanan dll agar layanan kami semakin luas
              dan menjadi virtual hospital" ujar Dr Tony Sukentro SpB.
            </p>
            <p className="pt-3">
              Semoga dengan sedikit bantuan anak bangsa ini maka kebutuhan
              masyarakat akan pengetahuan di bidang bedah akan semakin akurat
              dan tepat sehingga pasien mendapatkan kesembuhan yang optimal.{" "}
            </p>
            <p className="pt-3">
              Dr Tony juga mengharapkan dukungan dari rekan2 dokter, influencer,
              artis dan pemerintah serta mengajak untuk memperkenalkan trobosan
              terbaru dunia medis digital ini ke seluruh Indonesia atau bahkan
              dunia, sehingga wisatawan yang datang ke Indonesia merasakan
              keamanan saat berpergian dimanapun di Indonesia bila membutuhkan
              layanan konsultasi segera klik website{" "}
              <LinkRouter to="/" className="text-green-600">
                <span className="italic">www.bedahumum.com</span>
              </LinkRouter>{" "}
              dan akan segera direspon oleh dokter terkait.
            </p>
            <p className="pt-3">
              Dengan ini pula maka medical tourism di Indonesia akan semakin
              berkembang pesat dan bangsa Indonesia semakin mencintai dokternya,
              tidak lagi mencari dokter di luar negri. Maju terus kesehatan di
              Indonesia dan Salam Sehat{" "}
              <LinkRouter to="/" className="text-green-600">
                <span className="italic">www.bedahumum.com</span>
              </LinkRouter>{" "}
              .
            </p>
          </p>
        </div>
      </section>

      <FloatingWhatsApp
        phoneNumber="+628170138883"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={avt}
      />
    </>
  );
};

export default Intro;
