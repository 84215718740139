import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { gallery } from "../data/gallery.js";
import "../components/gallery.css";

const Gallery = () => {
  const [model, setModel] = useState(false);
  const [tempimgScr, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };
  gallery.sort((a, b) => b.id - a.id);

  return (
    <div name="gallery" className="w-full bg-[#BBD6B8] text-gray-700">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 text-gray-700 border-green-600 ">
            My Gallery
          </p>
        </div>

        {/* <div name='gallery' className='w-full h-screen bg-[#BBD6B8] text-gray-700'>Gallery */}
        <br />
        <div className="md:columns-3 sm:columns-2 bg-[#BBD6B8] text-gray-700 ">
          {gallery.map((item, index) => {
            return (
              <div
                className="pics"
                key={index}
                onClick={() => getImg(item.imgSrc)}
              >
                <img src={item.imgSrc} alt="dokton" style={{ width: "100%" }} />
              </div>
            );
          })}

          <div className={model ? "model open" : "model"}>
            <img src={tempimgScr} alt="popimg" />
            <GrClose onClick={() => setModel(false)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
