import React from "react";
import NavbarArtikel from "../components/NavbarArtikel";
import SinglePage from "./Single";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avt from "../assets/images/avatar/avatarsupport.png";

const SingleWrap = () => {
  const isSingle = true;

  return (
    <>
      <NavbarArtikel isSingle={isSingle} />
      <SinglePage />
      <FloatingWhatsApp
        phoneNumber="+628170138883"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={avt}
      />
    </>
  );
};

export default SingleWrap;
