import laparoscopysurgeryicon from '../assets/images/speciality/laparoscopysurgeryicon.png'
import diebeticfooticon from '../assets/images/speciality/diebeticfooticon.png'
import vascularsurgeryicon from '../assets/images/speciality/vascularsurgeryicon.png'
import herniarepairicon from '../assets/images/speciality/herniarepairicon.png'
import varicesstherapyicon from '../assets/images/speciality/varicesstherapyicon.png'
import spinetreatmenticon from '../assets/images/speciality/spinetreatmenticon.png'
import laserproctologyicon from '../assets/images/speciality/laserproctologyicon.png'

export const data=[
    {
        id:1,
        name:"Bedah Laparoscopy",
        desc:"Laparoskopi adalah jenis operasi lubang kunci yang digunakan untuk memeriksa dan mengoperasi organ di dalam area perut (perut) dan panggul. Laparoskopi dapat digunakan untuk diagnosis, dengan melihat organ dalam atau mengambil sampel jaringan (biopsi). Ini juga dapat digunakan untuk mengatasi masalah pembedahan pada saat yang bersamaan.",
        image:laparoscopysurgeryicon, 
     },
    {
        id:2,
        name:"Proktologi Laser",
        desc:"Proktologi laser mengacu pada pengobatan penyakit usus besar, anus, dan rektum melalui aplikasi laser. Ambeien, Fistula, Fisura, Polip, dan Sinus Pilonidal adalah beberapa penyakit yang dapat diobati secara efektif dengan menggunakan teknik laser.",
        image:laserproctologyicon,  
    },
    {
        id:3,
        name:"Perawatan Tulang Belakang",
        desc:"Terapis akan melakukan evaluasi mendalam, yang dikombinasikan dengan diagnosis dokter, akan menentukan pengobatan yang dirancang khusus untuk pasien dengan nyeri tulang belakang. Terapi mungkin termasuk traksi panggul, pijatan lembut, terapi es dan panas, USG, stimulasi otot listrik, dan latihan peregangan.",
        image:spinetreatmenticon, 
    },
    {
        id:4,
        name:"Terapi Varises",
        desc:"Varises adalah pembuluh darah yang melebar secara tidak normal. Varises dapat pecah dan berdarah, dan pendarahan dari varises esofagus biasanya merupakan keadaan darurat medis dengan risiko kematian yang tinggi. Perdarahan varises mengacu pada perdarahan varises yang ditemukan di seluruh saluran pencernaan, seperti di kerongkongan, lambung, dan rektum.",
        image:varicesstherapyicon,  
    },
    {
        id:5,
        name:"Perbaikan Hernia",
        desc:"Selama operasi perbaikan hernia, ahli bedah: Mendorong jaringan yang menonjol kembali ke tempatnya. Memperbaiki jaringan ikat dan otot yang melemah, yang disebut cacat hernia. Dapat menggunakan bahan jaring pendukung untuk memperkuat area yang melemah dan membantu jaringan tetap di tempatnya.",
        image:herniarepairicon,       
    },
    {
        id:6,
        name:"Bedah pembuluh darah",
        desc:"Bedah vaskular mengacu pada operasi terbuka dan prosedur invasif minimal yang menangani berbagai masalah pembuluh darah. Ini termasuk aneurisma aorta, penyakit arteri perifer, penyakit arteri karotis, dan insufisiensi vena kronis.",
        image:vascularsurgeryicon,       
    },
    {
        id:7,
        name:"Kaki penderita diabetes",
        desc:"Kaki diabetik adalah salah satu komplikasi diabetes yang paling signifikan dan merugikan, dan didefinisikan sebagai kaki yang terkena ulserasi yang berhubungan dengan neuropati dan/atau penyakit arteri perifer pada ekstremitas bawah pada pasien diabetes.",
        image:diebeticfooticon,       
    },

]