import React from "react";

export const NotFound = () => {
  return (
    <>
      <div>
        <h1>Page Not Found</h1>
      </div>
      <div>
        <a href="/">Back To Home</a>
      </div>
    </>
  );
};
