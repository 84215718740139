import React, { forwardRef } from "react";
import lp from "../assets/laparoscopy.png";
import proc from "../assets/proctology.png";
import spine from "../assets/spinesehat.png";
import kaki from "../assets/kakisehatkuprofile.png";

const Instagrams = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      name="Instagrams"
      className="w-full h-screen bg-[#BBD6B8] text-gray-700"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="sm:text-right pb-8 pl-4 ">
          <p className="text-4xl font-bold inline border-b-4 border-green-600">
            Other Instagrams
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 ">
          <div className="w-36 h-36  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <a
              href="https://www.instagram.com/laparoscopyku"
              target="_blank"
              rel="noreferrer"
            >
              <img src={lp} alt="laparascopyku" />
            </a>
          </div>
          <div className="w-36 h-36  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <a
              href="https://www.instagram.com/proctologyku"
              target="_blank"
              rel="noreferrer"
            >
              <img src={proc} alt="proctologyku" />
            </a>
          </div>
          <div className="w-36 h-36  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <a
              href="https://www.instagram.com/spinesehatku"
              target="_blank"
              rel="noreferrer"
            >
              <img src={spine} alt="spinesehatku" />
            </a>
          </div>
          <div className="w-36 h-36  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <a
              href="https://www.instagram.com/kakisehatku"
              target="_blank"
              rel="noreferrer"
            >
              <img src={kaki} alt="kakisehatku" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Instagrams;
