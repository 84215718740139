import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clientConfig from "../data/ClientConfig";
import Moment from "moment";
import renderHTM from "react-render-html";

const Single = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    // axios
    let url = `${clientConfig.siteUrl}/posts/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log("res", res);
        setPost(res.data);
      })
      .catch((err) => {
        console.log("error:", err.message);
      });
  }, [id]);
  return (
    <>
      <section className="bg-[#BBD6B8] p-10 min-h-screen">
        <div className="sm:text-center pb-8 pl-4 "></div>
        <div className="max-w-5xl mx-auto text-justify">
          {Object.keys(post).length ? (
            <div className="p-5">
              {/* // featured image */}
              {/* <div className="max-w-md">
                <img className="w-full" src={post.featured_src} alt="blog" />
              </div> */}
              <div className="text-xl sm:text-4xl font-bold">
                {post.title.rendered}
              </div>
              <div className="text-sm dark:text-gray-600">
                {Moment(post.date).format("LL")}
              </div>

              <div className="[&_ul]:list-disc [&_ul]:list-inside max-w-[700px] font-semibold text-justify sm:text-left">
                {renderHTM(post.content.rendered)}
              </div>
            </div>
          ) : (
            "Loading...."
          )}
        </div>
      </section>
    </>
  );
};
export default Single;
