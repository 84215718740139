import React, { forwardRef } from "react";
import { data } from "../data/speciality.js";

const Speciality = forwardRef((props, ref) => {
  const special = data;

  return (
    <div
      ref={ref}
      name="speciality"
      className="w-full bg-[#BBD6B8] text-gray-700"
    >
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
          <div className="pb-8">
            <p className="text-4xl font-bold inline border-b-4 border-green-600">
              Speciality
            </p>
            {/* <p className='py-6'>// Check out some of my work history</p> */}
          </div>
          <div className="max-w-[1000px] w-full grid grid-cols-1 md:grid-cols-10 gap-1">
            {special.map((item, index) => (
              <>
                <div className=" bg-slate-100 h-full flex items-center justify-center mx-2 px-2 md:mx-0 md:px-0">
                  <img
                    className="h-10 w-10  md:h-16 md:w-16"
                    src={item.image}
                    alt={item.name}
                  />
                </div>
                <div className=" bg-slate-100 h-full flex items-center justify-center col-span-1 md:col-span-2 mx-2 px-2 md:mx-0 md:px-0">
                  <text className="text-sm font-semibold text-center md:text-base">
                    {item.name}
                  </text>
                </div>
                <div className="bg-slate-100 h-full flex items-center justify-center col-span-1 md:col-span-7 mx-2 px-2 md:mx-0 md:px-0">
                  <p className="text-sm font-medium md:text-base">
                    {item.desc}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Speciality;
