import React, { forwardRef } from "react";
import iza from "../assets/images/sponsor/izamopro.jpg";
import awmi from "../assets/images/sponsor/logoawmi.png";
import hesroid from "../assets/images/sponsor/logohesroid.png";
import celltech from "../assets/images/sponsor/logocelltech.png";
import gt from "../assets/images/sponsor/logogt.png";

const Sponsors = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      name="sponsor"
      className="w-full h-screen bg-[#BBD6B8] text-gray-700"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="sm:text-right pb-8 pl-4 ">
          <p className="text-4xl font-bold inline border-b-4 border-green-600">
            Sponsored By
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-5  md:gap-14">
          <div className="w-48  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <a
              href="https://awmiofficial.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={awmi}
                alt="awmi official asosiasi wisata medis indonesia"
              />
            </a>
            <p className="font-semibold text-center">
              Asosisasi Wisata medis indonesia
            </p>
          </div>
          <div className="w-48  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <img src={iza} alt="izamopro suplemen kulit telur ikan salmon" />
            <p className="font-semibold text-center">
              Suplemen <br />
              Kulit Telur Ikan Salmon
            </p>
          </div>
          <div className="w-48  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <img
              src={hesroid}
              alt="hesroid solusi mengatasi hemoroid & varises"
            />
            <p className="font-semibold text-center">
              Solusi mengatasi Hemoroid & Varises
            </p>
          </div>
          <div className="w-48  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <img
              src={celltech}
              alt="celltech stem cell center by prof dr deby susanti"
            />
            <p className="font-semibold text-center">
              Celltech Stem Cell Center by Prof. dr. Deby Susanti Pada Vinski,
              MSc, PhD
            </p>
            {/* <p className="font-normal text-center font-xs">
              Prof. dr. Deby Susanti Pada Vinski, MSc, PhD
            </p> */}
          </div>
          <div className="w-48  shadow-[#94AF9F] hover:scale-110 duration-500 pt-2">
            <img src={gt} alt="extrak bunga telang antioxidan tetes" />
            <p className="font-semibold text-center">
              GarudaQU Extrak Bunga Telang Antioxidan Tetes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Sponsors;
